export function chiffresEnLettres(number) {
  const units = ['', 'Mille', 'Million', 'Milliard'];
  const numbers = ['', 'Un', 'Deux', 'Trois', 'Quatre', 'Cinq', 'Six', 'Sept', 'Huit', 'Neuf', 'Dix', 'Onze', 'Douze', 'Treize', 'Quatorze', 'Quinze', 'Seize', 'Dix-Sept', 'Dix-Huit', 'Dix-Neuf'];
  const tens = ['', '', 'Vingt', 'Trente', 'Quarante', 'Cinquante', 'Soixante', 'Soixante', 'Quatre-Vingt', 'Quatre-Vingt-Dix'];

  function convertGroup(group) {
    const unitsDigit = group % 10;
    const tensDigit = Math.floor((group % 100) / 10);
    const hundredsDigit = Math.floor(group / 100);

    let result = '';

    // Traitement des centaines
    if (hundredsDigit > 0) {
      if (hundredsDigit > 1) {
        result += `${numbers[hundredsDigit]} Cent `;
      } else {
        result += `Cent `;
      }
    }

    // Traitement des dizaines
    if (tensDigit === 1) {
      result += `${numbers[10 + unitsDigit]}`;
    } else {
      if (tensDigit > 1) {
        if (tensDigit === 7 || tensDigit === 9) {
          result += `${tens[tensDigit]}-${numbers[10 + unitsDigit]}`;
        } else {
          result += `${tens[tensDigit]} `;
          if (unitsDigit > 0) {
            result += numbers[unitsDigit];
          }
        }
      } else if (unitsDigit > 0) {
        result += numbers[unitsDigit];
      }
    }

    return result.trim();
  }

  let integerPart = Math.floor(number);
  let result = '';

  if (integerPart === 0) {
    result = 'Zéro';
  } else {
    let groupIndex = 0;
    while (integerPart > 0) {
      const group = integerPart % 1000;
      if (group > 0) {
        const groupText = convertGroup(group);
        if (groupIndex > 0 && group === 1 && groupIndex === 1) {
          result = `${units[groupIndex]} ${result}`;
        } else {
          result = `${groupText} ${units[groupIndex]} ${result}`;
        }
      }
      integerPart = Math.floor(integerPart / 1000);
      groupIndex++;
    }
  }

  return result.trim();
}
