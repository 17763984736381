<template>
    <div class="hello">
        <div class="screenUse">
          <Nav-bar style="width: 100%;"/>
          <vs-row>
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 100%; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 18px;"></vs-icon> DOSSIERS /</span> IMPORT
                  
                  <span style="float: right; margin-right: 150px;">
                    <vs-button color="#C32D39"  icon="add" style="padding: 5px; position: absolute; width: 150px;" @click="popupActivo2=true">Créer un ordre</vs-button>
                  </span>
                  <span style="float: right; margin-right: 200px;">
                    <el-button @click="popupActivoClient=true" round>Client</el-button> <el-button @click="popupActivoProduit=true" round>Produit</el-button>
                  </span>
                  <el-date-picker
                      v-model="dateSearch"
                      type="daterange"
                      range-separator="à"
                      start-placeholder="Date de début"
                      end-placeholder="Date de fin">
                    </el-date-picker>
                    <el-button icon="el-icon-search" circle @click="dateCherche()"></el-button>
                </div>
                <span style="float: right; margin-right: 150px;">
                  
                </span>   
                </vs-card>
              </div>
              
              <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 70px;">
  
                  <vs-divider position="left" style="width: 90%; margin: 20px auto">BILAN VOYAGES <b>BENNE</b></vs-divider>
  
  
  
                    <table border="1" ref="exportable_table" v-show="false">
                      <tbody >
                      <tr>
                          <th>DATE</th>
                          <th>BON</th>
                          <th>CLIENT</th>
                          <th>DISTANCE</th>
                          <th>CAMION</th>
                          <th>CHAUFFEUR</th>
                          <th>POIDS</th>
                      </tr>
                      <tr v-for="(item, i) in donneesDossiers" :key="i">
                        <td >{{item.dte_voyage}}</td>
                          <td>{{item.bon}}</td>
                          <td>{{item.client}}</td>
                          <td>{{item.chargement}} - {{item.dechargement}}</td>
                          <td>{{item.camion}}</td>
                          <td>{{item.chauffeur}}</td>
                          <td>{{item.poids}}</td>
                      </tr>
                      </tbody>
                  </table>
  
                  <div style="background-color: white;">
                    <vs-row>
                      
                      <vs-col vs-w="12" style="display: grid;">
                        <vs-card style="font-size: 12px; width: 90%; margin: 0 auto; border-radius: 20px; padding: 15px">
                          <vs-table stripe search max-items="20" pagination  :data="donneesDossiers" style="font-size: 12px">
                            <template slot="header">
                                <h3>LISTE DES BONS <el-link type="success" @click="ExportExcel('xls')"><img src="../../assets/excel.png" width="14"> EXPORTER</el-link></h3>
                                <span style="margin-left: 25px; cursor: pointer" @click="popupActivo4=true"></span>
                            </template>
                            <template slot="thead">
                              <vs-th>
                                Date
                              </vs-th>
                              <vs-th>
                                Bon
                              </vs-th>
                              <vs-th>
                                Client/Produit
                              </vs-th>
                              <vs-th>
                                Charge/Decharge
                              </vs-th>
                              <vs-th>
                                Chauffeur
                              </vs-th>
                              <vs-th>
                                Camion
                              </vs-th>
                              <vs-th>
                                Poids
                              </vs-th>
                              <vs-th>
                                Action
                              </vs-th>
                              
                            </template>
          
                            <template slot-scope="{data}">
                              <vs-tr :state="data[indextr].bon == 1000000 ?'success':data[indextr].bon == 3" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].dte_voyage">
                                  <span style="color: blue "  @click="ChangeStatut()">{{data[indextr].dte_voyage}}</span>
                                </vs-td>
          
                                <vs-td style="font-size: 12px;" :data="data[indextr].username">
                                  <span style="font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].bon}}</span>
                                </vs-td>
          
                                <vs-td style="font-size: 12px" :data="data[indextr].username">
                                  <vs-chip transparent color="primary">
                                    <b style="font-size: 11px; color: blue;">{{ data[indextr].client }}</b>-<b style="color: black; font-weight: normal">{{ data[indextr].produit }}</b>
                                  </vs-chip>
                                  
                                </vs-td>
          
                                <vs-td style="font-size: 12px;" :data="data[indextr].type_c">
                                  <span style="color: #8708088f">{{data[indextr].chargement}}</span> / {{data[indextr].dechargement}}
                                </vs-td>
          
                                <vs-td style="font-size: 12px; font-weight: bold;" :data="data[indextr].type_c">
                                  
                                  <vs-chip transparent color="dark">
                                    <vs-avatar />
                                    {{data[indextr].chauffeur}}
                                  </vs-chip>
                                </vs-td>
                                <vs-td style="font-size: 12px; font-weight: bold;" :data="data[indextr].type_c">
                                  <vs-icon style="font-size: 12px" icon="local_shipping"></vs-icon> {{data[indextr].camion}}
                                </vs-td>

                                <vs-td style="font-size: 14px; font-weight: bold; color: blue;" :data="data[indextr].type_c">
                                  {{data[indextr].poids}} T
                                </vs-td>

                                <vs-td style="font-size: 12px" :data="data[indextr].type_c">
                                  <el-button type="primary" icon="el-icon-edit" circle  @click="Modif(data[indextr])"></el-button>
                                  <el-button type="danger" icon="el-icon-delete" circle  @click="supBon(data[indextr].id_bon, data[indextr].camion, data[indextr].bon)"></el-button>
                                </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </div>
                  
  
                
                <vs-divider/>
                
              </div>
  
            </vs-col>
          </vs-row>

          <vs-popup class="holamundo"  title="Ajouter un client" :active.sync="popupActivoClient">
            <vs-input style="width: 50%; margin-left: 25%" size="medium" class="inputx" placeholder="Client" v-model="FormClient"/>
            <center><vs-button style="width: 200px; margin-top: 20px" color="primary" type="border" @click="onSubmitClient()">Enregistrer</vs-button></center>
          </vs-popup>

          <vs-popup class="holamundo"  title="Ajouter un produit" :active.sync="popupActivoProduit">
            <vs-row style="width: 60%; margin: 0 auto">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5.5">
                <vs-select
                    style="width: 100%;"
                    placeholder="Client"
                    v-model="cliselect"
                    >
                    <vs-select-item :key="index" :value="item.libelle" :text="item.libelle" v-for="item,index in donneesClients" />
                  </vs-select>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5.5">
                <el-input le="width: 100%;" placeholder="Produit" v-model="cliproduit"></el-input>
              </vs-col>
            </vs-row>
            
            <center><vs-button style="width: 200px; margin-top: 20px" color="primary" type="border" @click="onSubmitProduit()">Enregistrer</vs-button></center>
          </vs-popup>
  
          <vs-popup classContent="popup-example"  title="Nouveau bon" :active.sync="popupActivo2">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Client</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.client"
                    >
                    <vs-select-item :key="index" :value="item.libelle" :text="item.libelle" v-for="item,index in donneesClients" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Produit</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.produit"
                    >
                    <vs-select-item :key="index" :value="item.client" :text="item.client" v-for="item,index in donneesProduits" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>N° Bon</label>
                  <el-input v-model="form.bon" placeholder="N° bon">
                  </el-input>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Date Voyage</label>
                  <el-input   type="date" v-model="form.dte_voyage" placeholder="Date voyage">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Camion</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Camion"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.camion"
                    >
                    <vs-select-item :key="index" :value="item.matricule" :text="item.matricule" v-for="item,index in donneesCamion" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chauffeur</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Chauffeur"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.chauffeur"
                    >
                    <vs-select-item :key="index" :value="item.nom +' '+item.prenom" :text="item.nom +' '+item.prenom" v-for="item,index in donneesChauf" />
                  </vs-select>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Poids</label><br/>
                  <el-input-number width="100%" v-model="form.poids" :precision="2" :step="0.1" :max="100"></el-input-number>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chargement</label>
                  <el-input style="width: 100%;" v-model="form.chargement" placeholder="Lieu de chargement">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Déchargement</label>
                  <el-input style="width: 100%;" v-model="form.dechargement" placeholder="Lieu de déchargement">
                  </el-input>
                </vs-col>
              </vs-row>
              
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="onSubmit()" color="primary" type="filled">Enregistrer le bon</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>

          <vs-popup classContent="popup-example"  title="Modif bon" :active.sync="popupActivo22">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Client</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.client"
                    >
                    <vs-select-item :key="index" :value="item.libelle" :text="item.libelle" v-for="item,index in donneesClients" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Produit</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.produit"
                    >
                    <vs-select-item :key="index" :value="item.libelle" :text="item.libelle" v-for="item,index in donneesProduits" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>N° Bon</label>
                  <el-input v-model="form.bon" placeholder="N° bon">
                  </el-input>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Date Voyage</label>
                  <el-input   type="date" v-model="form.dte_voyage" placeholder="Date voyage">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Camion</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Camion"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.camion"
                    >
                    <vs-select-item :key="index" :value="item.matricule" :text="item.matricule" v-for="item,index in donneesCamion" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chauffeur</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Chauffeur"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.chauffeur"
                    >
                    <vs-select-item :key="index" :value="item.nom +' '+item.prenom" :text="item.nom +' '+item.prenom" v-for="item,index in donneesChauf" />
                  </vs-select>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Poids</label><br/>
                  <el-input-number width="100%" v-model="form.poids" :precision="2" :step="0.1" :max="100"></el-input-number>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chargement</label>
                  <el-input style="width: 100%;" v-model="form.chargement" placeholder="Lieu de chargement">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Déchargement</label>
                  <el-input style="width: 100%;" v-model="form.dechargement" placeholder="Lieu de déchargement">
                  </el-input>
                </vs-col>
              </vs-row>
              
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="onUpdate()" color="primary" type="filled">Modifier le bon</vs-button></center>
          </vs-popup>
        </div>
       
    </div>
  </template>
  
  <script>
  import { isMobile } from 'mobile-device-detect';
  import * as XLSX from "xlsx";
  import axios from 'axios';
  import NavBar from '@/components/bennes/Navbar.vue'
  export default {
    name: 'HelloWorld',
    components: {
      NavBar
    },
    computed: {
      users() {
        return this.$store.state.users;
          },
        },
    data() {
      return {
        form: {
          client: '',
          produit: '',
          bon: '',
          dte_voyage: '',
          camion: '',
          chauffeur: '',
          poids: '',
          chargement: '',
          dechargement: '',
          entreprise: 1,
          id_bon: 0,
          },
          cliproduit: '',
          cliselect: '',
          FormClient: '',
        popupActivoClient: false,
        popupActivoProduit: false,
        popupActivo4:false,
        choixMob: 'import',
        isMobiles : isMobile,
        search: '',
        dossierRef: '',
        facturation: false,
        counter: false,
        prixUnit: 0,
        dateSearch: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
          ],
        popupActivo2:false,
        popupActivo22:false,
        popupActivo3:false,
        activePrompt9:false,
        select1:3,
        formDate: {
          dateBen: ''
        },
        options: [{
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }, {
          value: 'Option5',
          label: 'Option5'
        }],
        checkTrue: true,
        donneesClient: null,
        donneesCliProd: null,
        donneesClients: null,
        donneesProduits: null,
        checkFalse: false,
        totalVoyages:0,
        donnees: null,
        don_Clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Clients2: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Camion: null,
        donneesStatsMois: null,
        donneesStatsSemaine: null,
        donneesArmateurs: null,
        donneesSheet: null,
        deletebon: 0,
        donneesZones: null,
        donneesCamion: null,
        donneesChauf: null,
        donneesDossiers: null,
        donneesDossiersBadt: null,
        donneesDossiersNbadt: null,
        deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
        like: true,
          value1: '',
          value2: '',
          title: 'Growth this year',
        activeName: 'first',
          clients: [{
            value: 'LEB',
            label: 'LEB'
          }, {
            value: 'CIM IVOIRE',
            label: 'CIM IVOIRE'
          }, {
            value: 'IVOMINE',
            label: 'IVOMINE'
          }, {
            value: 'SHILOH MANGANESE',
            label: 'SHILOH MANGANESE'
          }],
          produits: [{
            value: 'BAUXITE',
            label: 'BAUXITE'
          }, {
            value: 'TUFF',
            label: 'TUFF'
          }, {
            value: 'CIMENT',
            label: 'CIMENT'
          }, {
            value: 'MANGANESE',
            label: 'MANGANESE'
          }],
      }
    },
    methods: {

      Modif(data) {
        this.form = data;
        this.popupActivo22 = true;
        console.log(data);
      },
  
      choixMoba(a) {
        this.choixMob = a;
      },
  
          printYear: function () {
              return new Date().getFullYear() + 
              '' + new Date().getMonth()+ 
              '' + new Date().getDate()+
              '' + new Date().getHours()+
              '' + new Date().getMinutes()+
              '' + new Date().getMilliseconds()+
              '' + new Date().getSeconds();
        },
  
      choixPeriode(a) {
        this.periode = a;
        console.log(this.periode);
      },
  
      dateCherche() {
        const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;
  
        this.$router.push({ name: 'Conteneur_recherche', params: { debut: dte_db, fin: dte_fn, } });
      },
  
      listeDossiers () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sygestra.com/appi_sygestra/ajout_bon.php?ent='+this.users.n_ent+'&timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesDossiers = response.data.data;
                this.donneesCamion = response.data.camion;
                this.donneesChauf = response.data.chauf;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          listeClients () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sygestra.com/appi_sygestra/sygestra_benne_client.php?ent='+this.users.n_ent+'&timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesClients = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          listeProduits () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sygestra.com/appi_sygestra/sygestra_benne_produit.php?ent='+this.users.n_ent+'&timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesProduits = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
        ExportExcel(type, fn, dl) {
            var elt = this.$refs.exportable_table;
            var wb = XLSX.utils.table_to_book(elt, {sheet:"Sheet JS"});
            return dl ?
              XLSX.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
            XLSX.writeFile(wb, fn || ((this.name + '.'|| 'SheetJSTableExport.') + (type || 'xlsx')));
          },
  
        searchDate () {
            
            this.$vs.loading({
            type:'point',
            })
            const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;
  
            fetch('https://socogetra.net/appi_sococit/albakoos_produits_clients_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
            .then(response => response.json())
            .then(data => {
              this.donneesClient = data.data;
              this.$vs.loading.close()
            });
  
            axios
                .get('https://socogetra.net/appi_sococit/albakoos_stats_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
                .then(response => (this.don_Clients = response.data.dn_clients))
  
        },
  
        listeDossier () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_dossier_sbtl.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donnees = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        formatMoney(value) {
          const numberValue = parseFloat(value.replace(/ /g, '').replace(/,/g, '.'));
          if (isNaN(numberValue)) {
            return '';
          }
          return numberValue.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'XOF'
          });
        },
  
        listeZones () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_zones.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesZones = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          showClient(client) {
            this.ClientChoisi = client;
            console.log(this.ClientChoisi);
            this.popupActivo2 = true
          },
  
          generateReport () {
            this.$refs.html2Pdf.generatePdf()
            this.popupActivo2 = false;
            this.activePrompt9 = true;
          },
  
          listeChauffeurs () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_chauffeurs.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesChauf = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          verif() {
            if(this.users.conteneur == "KO") {
                this.$router.push({ path: '/dashboard' });
            }
          },
  
         // generatePDF () {
          //  var doc = new jsPDF();
  
            // Récupérer l'élément HTML que vous voulez convertir en PDF
          //  const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
          //  html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF
           //   const imgData = canvas.toDataURL('image/png')
            //  doc.addImage(imgData, 'PNG', 10, 10)
  
              // Sauvegarder le document PDF
           //   doc.save('example.pdf')
          //  })
         // },
  
          listeCamion () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_camion.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesCamion = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          sheet () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://docs.google.com/spreadsheets/d/1hsitLRk83_0P5yJQUC6A9dNB8OQ2HfOI-9BGfy0VF3g/edit#gid=0')
              .then((response) => {
                this.donneesSheet = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          handleSelectChange(choix) {
            console.log(choix);
            const oumarItem = this.donneesZones.find(item => item.zone_livraison === choix);
            this.form.zone_facturation = oumarItem ? oumarItem.zone_facturation : null;
          },

          supBon(id, c, b) {
              this.deletebon = id;
              this.$vs.dialog({
              color:'danger',
              title: `Suppression`,
              text: 'Êtes vous sûre de vouloir supprimer ? ' + b + ' du camion ' + c,
              accept:this.onDelete
            })
          },

          onDelete() {
                axios.delete('https://sygestra.com/appi_sygestra/ajout_bon.php?id='+this.deletebon)
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    this.popupActivo2 = false;
                    this.listeDossiers();
                    this.$notify.success({
                    title: 'Bon !',
                    message: 'Supprimé avec Succès',
                    showClose: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    this.$vs.loading.close()
                });
            },
  
        onSubmit() { 
          this.form.entreprise = this.users.n_ent;
              fetch('https://sygestra.com/appi_sygestra/ajout_bon.php', {
                method: 'POST',
                body: JSON.stringify(this.form),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.listeDossiers();
              })
              .then(data => {
                console.log(data);
                this.listeDossiers();
                this.popupActivo2 = false;
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Bon OK!',
                  message: 'Bon Ajouté',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },

          onSubmitClient() { 
            let form = {
              libelle : this.FormClient,
              ent : this.users.n_ent,
            };
              fetch('https://sygestra.com/appi_sygestra/sygestra_benne_client.php', {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.popupActivoClient = false;
                this.listeClients();
              })
              .then(data => {
                console.log(data);
                this.listeClients();
                this.popupActivoClient = false;
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Client OK!',
                  message: 'Client Ajouté',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },

          onSubmitProduit() { 
            let form = {
              libelle : this.cliselect,
              client : this.cliproduit,
              ent : this.users.n_ent,
            };
              fetch('https://sygestra.com/appi_sygestra/sygestra_benne_produit.php', {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.popupActivoProduit = false;
                this.listeProduits();
              })
              .then(data => {
                console.log(data);
                this.listeProduits();
                this.popupActivoProduit = false;
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Produit OK!',
                  message: 'Produit Ajouté',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },

          onUpdate() {
          this.form.entreprise = this.users.n_ent;
              fetch('https://sygestra.com/appi_sygestra/ajout_bon.php', {
                method: 'PUT',
                body: JSON.stringify(this.form),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.listeDossiers();
                this.popupActivo22 = false;
              })
              .then(data => {
                console.log(data);
                this.listeDossiers();
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Bon OK!',
                  message: 'Bon Ajouté',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },
  
        clickFn() {
          this.$refs.statistic.suspend(this.stop);
          this.stop = !this.stop;
        },
        handleClick(tab, event) {
          console.log(tab, event);
        },
        hilarity() {
          this.$notify({
            title: 'TRAVAIL DU JOUR TERMINE',
            message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
            duration: 0,
          });
        },
  
  
          getData() {
            const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA1REVBNjlBOUIxODE4RjI3RkIxNUQ2QzQ2QkI0NEU0IiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FjY291bnQubmF2aXMuY29tIiwibmJmIjoxNjg0MTAzMjY3LCJpYXQiOjE2ODQxMDMyNjcsImV4cCI6MTY4NDExMDQ2NywiYXVkIjpbImNvbXBhbnljbG91ZC1wdWJsaWNhcGkiLCJodHRwczovL2FjY291bnQubmF2aXMuY29tL3Jlc291cmNlcyJdLCJzY29wZSI6WyJjb21wYW55Y2xvdWQtcHVibGljYXBpIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJTbWFydEFjY2Vzcy1BcHAiLCJzdWIiOiI4NDVmNTIzYS0xMTBjLTQ4NjQtODVkNC1hYzE3NzlkN2YxZDAiLCJhdXRoX3RpbWUiOjE2ODQxMDMyNjYsImlkcCI6ImxvY2FsIiwiZW1haWwiOiJzYWtvbmVAc29jb2dldHJhLm5ldCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNha29uZUBzb2NvZ2V0cmEubmV0Iiwiam9pbmRhdGUiOiIwNS8wMy8yMDIzIDEwOjI1IiwicGljdHVyZSI6Imh0dHBzOi8vaW1hZ2VzLmFjY291bnQubmF2aXMuY29tL2VmZmVjNmUyLTIzNWQtNDNjMy05YmVmLTNlNTBkZDM0MmEwMS8xMDAucG5nIiwiZ2l2ZW5fbmFtZSI6IlNBS09ORSIsImZhbWlseV9uYW1lIjoiT3VtYXIiLCJzaWQiOiJFMkE2QzU4RkVDQzM0MDlFNjk4NDUzMURGNjc4RUMxQyJ9.rPA25RdckhXhl5sWBjVhI-C-8rIu3dX-w9zP2rk1Kwfpw8ED9LYV3J8d2sQknShx8vGTaZwSCiQLC55KNM1mi1b603uH8QEqz-Pb1Ql-wNWv_5NHIDdEG7d45G2KEX_CNjKBnNHS6agoatJbbjHQymdxmaudwQRqDJo9-mDXsG_ZUwD-vjYFQKvFqBzVJricJZiwrDAZXXIp9Yc2AvOVFsQs3DHXHk8GmY98q3-qt_Dfbr1UiJM-iejYzCO-dAa-YX2D78XK0UG9ejMcwKB1jBXpt0TgLMSHKIKcQSGVX5qPj_IHBB_dFgf3nNmrCz7mgoe4p1BCWHuItfk2iF7ccg'
            axios.get('https://api-smartaccess.cotedivoireterminal.com/smartaccess/api/gateway/vessel/visits', {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'accountIdentifier': 'takt',
                'accountType': 'Haulier',
                'partnerAccountIdentifier': 'cit',
                'appIdentifier': 'smrtaccess',
                'Authorization': 'Bearer '+token,
              }
            })
            .then(response => {
              console.log(response.data);
            })
            .catch(error => {
              console.error(error);
            });
          }
      },
  
      mounted() {
        this.listeDossiers();
        this.listeClients();
        this.listeProduits();
  
      },
      
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .el-input__inner::placeholder {
      color: #201f1f;
  }
  .custom-input1 {
    font-size: 24px;
  }
  .custom-input1 .el-input__inner {
    font-size: 24px;
    padding-top: 18px !important; /* Ajuste l'espacement interne pour descendre le texte */
  }
  .custom-input2 {
    font-size: 19px;
  }
  </style>
  