import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    users: {
      name : '',
      administration : 'KO',
      benne : 'KO',
      citerne : 'KO',
      cloud : 'KO',
      comptabilite : 'KO',
      conteneur : 'KO',
      facturation : "KO",
      entreprise : '',
      entreprise_long : '',
      entreprise_contact : '',
      email_ent : '',
      adresse_ent : '',
      n_ent : '1',
      identifiant : '',
      lecture : "KO",
      mdp : '',
      nom : '',
      photo : '',
      prenom : '',
      titre : '',
      logo : '',
      admin : 'KO'
    }
  },
  mutations: {
    Connexion: (state, data) => {
      state.users.administration = data.administration;
      state.users.benne = data.benne;
      state.users.citerne = data.citerne;
      state.users.cloud = data.cloud;
      state.users.comptabilite = data.comptabilite;
      state.users.conteneur = data.conteneur;
      state.users.facturation = data.facturation;
      state.users.identifiant = data.identifiant;
      state.users.entreprise = data.libelle;
      state.users.entreprise_long = data.libelle_long;
      state.users.entreprise_contact= data.contact1;
      state.users.adresse_ent= data.adresse;
      state.users.email_ent = data.email1;
      state.users.n_ent = data.entreprise;
      state.users.lecture = data.lecture;
      state.users.mdp = data.mdp;
      state.users.nom = data.nom;
      state.users.name = data.nom;
      state.users.prenom = data.prenom;
      state.users.photo = data.photo;
      state.users.titre = data.titre;
      state.users.logo = data.logo;
      state.users.admin = 'KO';
    },
    Connexiona: (state, data) => {
      state.users.administration = data.administration;
      state.users.benne = data.benne;
      state.users.citerne = data.citerne;
      state.users.cloud = data.cloud;
      state.users.comptabilite = data.comptabilite;
      state.users.conteneur = data.conteneur;
      state.users.facturation = data.facturation;
      state.users.identifiant = data.identifiant;
      state.users.entreprise = data.libelle;
      state.users.n_ent = data.entreprise;
      state.users.lecture = data.lecture;
      state.users.mdp = data.mdp;
      state.users.nom = data.nom;
      state.users.name = data.nom;
      state.users.prenom = data.prenom;
      state.users.photo = data.photo;
      state.users.titre = data.titre;
      state.users.logo = data.logo;
      state.users.admin = 'OK';
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
