import { render, staticRenderFns } from "./Historique.vue?vue&type=template&id=2b2233a8&scoped=true"
import script from "./Historique.vue?vue&type=script&lang=js"
export * from "./Historique.vue?vue&type=script&lang=js"
import style0 from "./Historique.vue?vue&type=style&index=0&id=2b2233a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2233a8",
  null
  
)

export default component.exports